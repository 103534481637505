@import url(https://fonts.googleapis.com/css?family=Staatliches);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Fauna+One:200,300,400,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.question-circle{display:inline;margin-left:6px;margin-bottom:3px;color:var(--chakra-colors-gray-200)}.question-circle:hover{color:var(--chakra-colors-gray-300);transition:.25s ease}.hover-teardrop{opacity:0;z-index:10}.top-teardrop:hover .hover-teardrop{opacity:1;transition:.3s ease}
