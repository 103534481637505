/*
============================================================

                        FONT IMPORTS

============================================================
*/

//Staatliches
@import "https://fonts.googleapis.com/css?family=Staatliches";

//Source Sans Pro
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700";

//Source Sans Pro
@import "https://fonts.googleapis.com/css?family=Fauna+One:200,300,400,600,700";

/*
============================================================

                        Classees

============================================================
*/

.question-circle {
  display: inline;
  margin-left: 6px;
  margin-bottom: 3px;
  color: var(--chakra-colors-gray-200);

  &:hover {
    color: var(--chakra-colors-gray-300);
    transition: 0.25s ease;
  }
}

.hover-teardrop {
  opacity: 0;
  z-index: 10;
}

.top-teardrop:hover {
  .hover-teardrop {
    opacity: 1;
    transition: .3s ease;
  }
}
